import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import SentryFullStory from '@sentry/fullstory'
import { Dedupe } from '@sentry/integrations/esm/dedupe'
import { RewriteFrames } from '@sentry/integrations/esm/rewriteframes'
import { Game } from '@src/types/game'

let game: Game | null = null

export const updateAnalyticsGame = (newGame?: Game) => {
  if (!newGame) return
  console.log('updateAnalyticsGame gameId:', newGame.gameID)
  game = newGame
} 

const setAnalytics = () => {
  const devMode = import.meta.env.DEV
  const prodMode = import.meta.env.PROD
  const root = import.meta.env.BASE_URL
  const orgId = import.meta.env.VITE_FULLSTORY_ORG_ID as string
  const sentryOrg = import.meta.env.VITE_SENTRY_ORG_SLUG as string
  const dsn = import.meta.env.VITE_SENTRY_DSN as string

  FullStory.init({ orgId, devMode })

  Sentry.init({
    dsn,
    environment: prodMode ? 'production' : 'development',
    normalizeDepth: 10,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    integrations: [
      new RewriteFrames({ root }),
      new SentryFullStory(sentryOrg),
      new Dedupe(),
    ],
    beforeSend(event) {
      if (game) {
        event.contexts = {
          ...event.contexts,
          Game: game,
        }
      } 
      return event
    }
  })
}

export const updateAnalyticsGameId = (gameId: string) => {
  console.log('updateAnalytics gameId:', gameId)
  FullStory.setUserVars({
    gameId
  })
}

export default setAnalytics
